import React from 'react';
import loadable from "@loadable/component";
import { Routes, Route, Navigate } from "react-router-dom";

// Sidebar Menu
import { LoaderProvider } from '../../context/Preloader';
import PageLayout from '../../layouts/PageLayout';
import menu from './data/menu.json';
import Error from '../../layouts/Error404';
import Visits from '../general/pages/Visits/Index';
import Notifications from '../general/pages/Notifications/Index';
import Appointments from '../general/pages/Appointments/Index';
import { PharmacyFilter } from '../../filters/PharmacyFilter';
import Loader from '../../layouts/Loader';

const LoadablePage = loadable((props) => import(`./pages/${props.page}`), {
    fallback: <Loader />,
    cacheKey: (props) => props.page
});

const PharmacyRouter = () => {
    return (
        <React.Fragment>
            <PharmacyFilter>
                <PageLayout menu={menu}>
                    <Routes>
                        <Route index element={<Navigate to='/pharmacy/dashboard' />} />
                        <Route path="dashboard" element={<LoadablePage page="Dashboard" />} />
                        <Route path="catalogues">
                            <Route index path='medications' element={<LoadablePage page="Catalogue/Medications/Index" />} />
                            <Route path="disposables" element={<LoadablePage page="Catalogue/Disposables/Index" />} />
                        </Route>

                        {/* =============== Prescriptions Routes ========== */}
                        <Route path="prescriptions">
                            <Route index element={<LoadablePage page="Prescriptions/Index" />} />
                            <Route path="history" element={<LoadablePage page="Prescriptions/History" />} />
                        </Route>

                        <Route path="reports" element={<LoadablePage page="Reports/Index" />} />
                        <Route path="restocks">
                            <Route index element={<LoadablePage page="Restocks/Create" />} />
                            <Route path="history" element={<LoadablePage page="Restocks/Index" />} />
                        </Route>
                        <Route path="dispensed" element={<LoadablePage page="Dispensed/Index" />} />
                        <Route path="settings" element={<LoadablePage page="Settings/Index" />} />

                        <Route path="visits" element={<Visits />} />
                        <Route path="notifications" element={<Notifications />} />
                        <Route path="appointments" element={<Appointments />} />

                        <Route path="*" element={<Error />} />
                    </Routes>
                </PageLayout>
            </PharmacyFilter>
        </React.Fragment>
    )
}

export default PharmacyRouter;