import React from "react";
import { Box, Text, Heading } from "./elements";

export default function DuelText({ size, title, descrip, timesTamp }) {
    return (
        <Box className={`mc-duel-text ${size ? size : "md"}`}>
            <Heading className="mc-duel-text-title">
                {title}
            </Heading>
            <Text className="mc-duel-text-descrip" style={{ marginTop: "1px" }}>{descrip}</Text>
            {timesTamp && <Text className="mc-duel-text-timestamp">{timesTamp}</Text>}
        </Box>
    )
}