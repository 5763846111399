import React from 'react';
import loadable from "@loadable/component";
import { Routes, Route, Navigate } from "react-router-dom";

// Sidebar Menu
import { LoaderProvider } from '../../context/Preloader';
import PageLayout from '../../layouts/PageLayout';
import menu from './data/menu.json';
import Error from '../../layouts/Error404';
import Visits from '../general/pages/Visits/Index';
import Notifications from '../general/pages/Notifications/Index';
import Appointments from '../general/pages/Appointments/Index';
import { LaboratoryFilter } from '../../filters/LaboratoryFilter';
import { Spin } from 'antd';
import Loader from '../../layouts/Loader';

const LoadablePage = loadable((props) => import(`./pages/${props.page}`), {
    fallback: <Loader />,
    cacheKey: (props) => props.page
});

const LaboratoryRouter = () => {
    return (
        <React.Fragment>
            <LaboratoryFilter>
                <PageLayout menu={menu}>
                    <Routes>
                        <Route index element={<Navigate to='/laboratory/dashboard' />} />
                        <Route path="dashboard" element={<LoadablePage page="Dashboard" />} />
                        <Route path="requests">
                            <Route index element={<LoadablePage page="Requests/Index" />} />
                            <Route path=":requestId" element={<LoadablePage page="Requests/Profile" />} />
                        </Route>

                        <Route path="results">
                            <Route index element={<LoadablePage page="Results/Index" />} />
                            <Route path=":resultId" element={<LoadablePage page="Results/Profile" />} />
                        </Route>

                        <Route path="investigations" element={<LoadablePage page="Investigations/Index" />} />

                        <Route path="units">
                            <Route index element={<LoadablePage page="Units/Index" />} />
                            <Route path=":unitId" element={<LoadablePage page="Units/Profile" />} />
                        </Route>

                        <Route path="settings" element={<LoadablePage page="Settings/Index" />} />

                        <Route path="visits" element={<Visits />} />
                        <Route path="notifications" element={<Notifications />} />
                        <Route path="appointments" element={<Appointments />} />

                        <Route path="*" element={<Error />} />
                    </Routes>
                </PageLayout>
            </LaboratoryFilter>
        </React.Fragment>
    )
}

export default LaboratoryRouter;