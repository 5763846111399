const FirstLoader = function () {
  if (window.localStorage) {
    const firstLoad = localStorage.getItem("firstLoad");
    if (firstLoad == "true") {
      localStorage["firstLoad"] = false;
      window.location.reload();
    }
  }
};

export default FirstLoader;
