import React, { useState, useEffect } from "react";
import { Card, Button, Table, Avatar, Tag, Badge, Space, Typography, Divider } from "antd";
import { ClockCircleOutlined, EyeOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons'
import { Item, Anchor, Box, Heading } from "../../../../components/elements";
import { Breadcrumb } from "../../../../components";


// import data from "../../../../data/master/userList.json";
import fetchApi from "../../../../database/axios";
import { useNavigate } from "react-router";
import { Col, Row } from "react-bootstrap";
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

// Database Call

const { Ribbon } = Badge
const { Meta } = Card
const { Text } = Typography
dayjs.extend(relativeTime)

export default function Index() {

    const navigate = useNavigate()
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchApi('get', 'visits/current').then(res => {
            res.data.data.forEach((item) => {
                item.key = item._id
                item.status = item.isEmergency ? 'emergency' : 'usual'
                if (Object.keys(item.admission ?? {}).length > 0) {
                    item.status = 'Admitted'
                    item.color = 'purple'
                }
            })
            setData(res.data.data);
        })
            .catch(err => { })
            .finally(() => setLoading(false))
    }, []);

    return (
        <>
            <Row>

                {/* Start Patient Table */}
                <Col md={12}>
                    <Card loading={loading} title={'Patient Visits List'}>
                        <Row>
                            {
                                data?.map((item, index) => {
                                    return (
                                        <Col key={index} md={4}>
                                            <Badge.Ribbon text={item.status} color={item.color ?? (item.isEmergency ? "red" : "blue")}>
                                                <Card title={''}
                                                    style={{ borderWidth: 2 }}
                                                    hoverable
                                                    actions={[
                                                        <Text><ClockCircleOutlined /> {dayjs(item?.createdAt).fromNow()}</Text>,
                                                        <Button type={'primary'} style={{ backgroundColor: 'grey' }} onClick={() => navigate(item._id)} shape={'default'} size={'medium'} icon={<EyeOutlined />}>view</Button>
                                                    ]}>
                                                    <Meta key={index} avatar={<Avatar shape={'square'} size={50} />} description={
                                                        <Space direction={'vertical'} size={6}>
                                                            <Text strong>{item?.patient?.fullName}</Text>
                                                            <Text><Text style={{ marginTop: 5 }} strong>{item?.patient?.hospitalNumber} </Text>
                                                                - {item?.patient?.age} years, {item?.patient?.gender}</Text>
                                                            <Divider type={'horizontal'} style={{ margin: 0 }} />
                                                            <Text><Badge status={'processing'} text={item.purpose ?? '--'} /> </Text>
                                                        </Space>
                                                    } />
                                                </Card>
                                            </Badge.Ribbon>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Card>
                </Col>
                {/* End Patient Table */}
            </Row>

        </>
    )
}