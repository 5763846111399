import React, { useState } from "react";
import {
  Box,
  Form,
  Heading,
  Anchor,
  Image,
  Text,
} from "../../../../components/elements";
import IconField from "../../../../components/fields/IconField";
import Logo from "../../../../components/Logo";
import fetchApi from "../../../../database/axios";
import jsonData from "../../../../data/master/login.json";
import { useAuth } from "../../../../context/Authentication";
import { useLocation, useNavigate } from "react-router-dom";
import { FlashMessage } from "../../../../layouts/FlashMessage";
import { toast } from "react-toastify";
import { Button } from "antd";

export default function Login() {
  const initialState = { email: "", password: "" };
  const [formData, setFormData] = useState(initialState);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // Todo: Redirect to previous page after login
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const redirectPath = location.state?.path;

  const handleLogin = () => {
    setLoading(true);
    fetchApi("post", "auth/login", formData)
      .then((res) => {
        redirect(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response?.data.error || err.message);
      });
  };

  const redirect = (data) => {
    auth.login(data);
    localStorage.setItem("firstLoad", true);
    if (!redirectPath) {
      switch (data.staff?.defaultRole) {
        case "admin":
          return navigate("/administration", { replace: true });
        case "receptionist":
          return navigate("/administration", { replace: true });
        case "nurse":
          return navigate("/medical", { replace: true });
        case "doctor":
          return navigate("/medical", { replace: true });
        case "pharmacist":
          return navigate("/pharmacy", { replace: true });
        case "lab_scientist":
          return navigate("/laboratory", { replace: true });
        case "accountant":
          return navigate("/account", { replace: true });
        case "patient":
          return navigate("/patient", { replace: true });
        default:
          return navigate("/", { replace: true });
      }
    }
    return navigate(redirectPath, { replace: true });
  };

  return (
    <Box className="mc-auth">
      <Image
        src={"images/pattern.webp"}
        alt={"line-pattern"}
        className="mc-auth-pattern"
      />
      <Box className="mc-auth-group">
        <Logo
          src={jsonData?.logo.src}
          alt={jsonData?.logo.alt}
          href={jsonData?.logo.path}
          className="mc-auth-logo"
        />
        <Heading as="h4" className="mc-auth-title">
          {process.env.REACT_APP_HOSPITAL_NAME}
        </Heading>

        {error ? <FlashMessage message={error} variant="danger" /> : ""}

        <Form className="mc-auth-form">
          <IconField
            icon={"email"}
            type={"email"}
            classes={"w-100 h-sm"}
            placeholder={"Enter your email or phone number"}
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
          />

          <IconField
            placeholder={"Enter your password"}
            type={"password"}
            classes={"w-100 h-sm"}
            passwordVisible={true}
            value={formData.password}
            onChange={(e) =>
              setFormData({ ...formData, password: e.target.value })
            }
            icon={"lock"}
          />

          <Button
            block
            onClick={() => handleLogin()}
            size={"large"}
            type={"primary"}
            loading={loading}
          >
            Sign in
          </Button>

          <Anchor className="mc-auth-forgot" href={"/forgot-password"}>
            {"Forgot Password?"}
          </Anchor>
          <Box className="mc-auth-divide">
            <Text as="span">or</Text>
          </Box>
          <Box className="mc-auth-connect">
            <Anchor href={"#"} className={"twitter h-sm"}>
              <i className={"icofont-twitter"}></i>
              <span>{"Continue with Twitter"}</span>
            </Anchor>
            <Anchor href={"#"} className={"facebook h-sm"}>
              <i className={"icofont-facebook"}></i>
              <span>{"Continue with Facebook"}</span>
            </Anchor>
          </Box>
        </Form>

        <Box className="mc-auth-navigate">
          <Text as="span">{"Don't have an account?"}</Text>
          <Anchor href={"#"}>{"Register"}</Anchor>
        </Box>
      </Box>
    </Box>
  );
}
