import React from 'react';
import loadable from "@loadable/component";
import { Routes, Route, Navigate } from "react-router-dom";

// Sidebar Menu
import { LoaderProvider } from '../../context/Preloader';
import PageLayout from '../../layouts/PageLayout';
import menu from './data/menu.json';
import Error from '../../layouts/Error404';
import { NurseFilter } from '../../filters/NurseFilter';
import { Spin } from 'antd';
import Loader from '../../layouts/Loader';

const LoadablePage = loadable((props) => import(`./pages/${props.page}`), {
    fallback: <Loader />,
    cacheKey: (props) => props.page
});

const LoadDynamic = loadable((props) => import(`../${props.page}`), {
    fallback: <Loader />,
    cacheKey: (props) => props.page
});

const NurseRouter = () => {
    return (
        <React.Fragment>
            <NurseFilter>
                <PageLayout menu={menu}>
                    <Routes>
                        <Route index element={<LoadablePage page="Dashboard" />} />
                        <Route path="dashboard" element={<LoadablePage page="Dashboard" />} />
                        <Route path="admission">
                            <Route index element={<LoadablePage page="Admission/Index" />} />
                            <Route path=':admissionId' element={<LoadDynamic page="general/pages/Visits/Profile" />} />
                        </Route>

                        <Route path="wards">
                            <Route index element={<LoadablePage page="Wards/Index" />} />
                            <Route path="rooms">
                                <Route index element={<LoadablePage page="Wards/Index" />} />
                                <Route path=":roomId/beds" element={<LoadablePage page="Wards/Beds/Index" />} />
                            </Route>
                        </Route>

                        <Route path="beds">
                            <Route index element={<LoadablePage page="Wards/Beds/Index" />} />
                        </Route>

                        <Route path="reports" element={<LoadablePage page="Reports/Index" />} />
                        <Route path="surgeries" element={<LoadablePage page="Surgeries/Index" />} />
                        <Route path="settings" element={<LoadablePage page="Settings/Index" />} />


                        <Route path='visits' >
                            <Route index element={<LoadDynamic page="general/pages/Visits/Index" />} />
                            <Route path=':visitId' element={<LoadDynamic page="general/pages/Visits/Profile" />} />
                        </Route>

                        <Route path="notifications" element={<LoadDynamic page="general/pages/Notifications/Index" />} />
                        <Route path="appointments" element={<LoadDynamic page="general/pages/Appointments/Index" />} />

                        <Route path="*" element={<Error />} />
                    </Routes>
                </PageLayout>
            </NurseFilter>
        </React.Fragment>
    )
}

export default NurseRouter;