import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/Authentication'

export const AccountFilter = ({ children }) => {
    const auth = useAuth();
    const allowedRoles = ['accountant', 'admin']

    if (auth.staff) {

        if (!(auth.staff.roles.some(role => allowedRoles.includes(role)))) {
            return <Navigate to={'/general'} />
        }
    }

    return children
}