import React from 'react';
import loadable from "@loadable/component";
import { Routes, Route, Navigate } from "react-router-dom";

// Sidebar Menu
import { LoaderProvider } from '../../context/Preloader';
import PageLayout from '../../layouts/PageLayout';
import menu from './data/menu.json';
import Error from '../../layouts/Error404';
import Visits from '../general/pages/Visits/Index';
import Notifications from '../general/pages/Notifications/Index';
import { NurseFilter } from '../../filters/NurseFilter';
import { Spin } from 'antd';
import Loader from '../../layouts/Loader';

const LoadablePage = loadable((props) => import(`./pages/${props.page}`), {
    fallback: <Loader />,
    cacheKey: (props) => props.page
});

const GeneralRouter = () => {
    return (
        <React.Fragment>
            <PageLayout menu={menu}>
                <Routes>
                    <Route index element={<Navigate to='/general/dashboard' />} />
                    <Route path='/dashboard' element={<LoadablePage page="Dashboard" />} />
                    <Route path='visits' >
                        <Route index element={<LoadablePage page="Visits/Index" />} />
                        <Route path=':visitId' element={<LoadablePage page="Visits/Profile" />} />
                    </Route>
                    <Route path='/settings' element={<LoadablePage page="Settings/Index" />} />
                    <Route path='/appointments' element={<LoadablePage page="Appointments/Index" />} />
                    <Route path='/notifications' element={<LoadablePage page="Notifications/Index" />} />

                    <Route path="*" element={<Error />} />
                </Routes>
            </PageLayout>
        </React.Fragment>
    )
}

export default GeneralRouter;