import React from "react";
import Main from "./Mian";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";
import { DrawerProvider } from "../context/Drawer";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../components";
import { Item, Anchor } from "../components/elements";
import { CardLayout } from "../components/cards";

export default function PageLayout({ children, menu }) {

    const location = useLocation();

    return (
        <DrawerProvider>
            <Header />
            <Sidebar menu={menu} />
            <Main>

                {children}
                {location.pathname !== "/message" ? <Footer /> : ""}
            </Main>
        </DrawerProvider>
    )
}