import { ClearOutlined } from '@ant-design/icons'
import { Button, Card, Empty } from 'antd'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Breadcrumb } from '../../../../components'
import { Anchor, Item } from '../../../../components/elements'

const Notifications = () => {
    return (
        <>
            <Row>
                {/* Start Breadcrumb */}
                <Col md={12}>
                    <Card>
                        <Breadcrumb title={'Notification Center'}>
                            <Item className="mc-breadcrumb-item">
                                <Anchor className="mc-breadcrumb-link" href={'#'}>{'Home'}</Anchor>
                            </Item>
                            <Item className="mc-breadcrumb-item"> Notifications </Item>
                            <Item className="mc-breadcrumb-item"> all-notifications </Item>
                        </Breadcrumb>
                    </Card>
                </Col>
                {/* End Breadcrumb */}

                <Col md={12}>
                    <Card title={'Notification Center'} extra={<Button type={'primary'} icon={<ClearOutlined span />}>Clear all</Button>}>
                        <Empty />
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default Notifications