import React from 'react';
import loadable from "@loadable/component";
import { Routes, Route, Navigate } from "react-router-dom";

// Sidebar Menu
import { LoaderProvider } from '../../context/Preloader';
import PageLayout from '../../layouts/PageLayout';
import menu from './data/menu.json';
import Error from '../../layouts/Error404';
import Visits from '../general/pages/Visits/Index';
import Notifications from '../general/pages/Notifications/Index';
import Appointments from '../general/pages/Appointments/Index';
import { AccountFilter } from '../../filters/AccountFilter';
import Loader from '../../layouts/Loader';

const LoadablePage = loadable((props) => import(`./pages/${props.page}`), {
    fallback: <Loader />,
    cacheKey: (props) => props.page
});

const AccountRouter = () => {
    return (
        <React.Fragment>
            <AccountFilter>
                <PageLayout menu={menu}>
                    <Routes>
                        <Route index element={<Navigate to='/account/dashboard' />} />
                        <Route path="dashboard" element={<LoadablePage page="Dashboard" />} />
                        <Route path="reports" element={<LoadablePage page="Reports/Index" />} />

                        {/* ================ Bills Routes ============= */}
                        <Route path="bills">
                            <Route index element={<LoadablePage page="Bills/Index" />} />
                            <Route path=':billId' element={<LoadablePage page="Bills/Profile" />} />
                            <Route path='archive'>
                                <Route index element={<LoadablePage page="Bills/Archive" />} />
                                <Route path=':billId' element={<LoadablePage page="Bills/Profile" />} />
                            </Route>
                        </Route>

                        {/* ================ Bill Items Routes ============= */}
                        <Route path="bill-items">
                            <Route index element={<LoadablePage page="BillItems/Index" />} />
                            <Route path=':billItemId' element={<LoadablePage page="BillItems/Profile" />} />
                        </Route>

                        {/* ================ Expenses Routes ============= */}
                        <Route path="expenses">
                            <Route index element={<LoadablePage page="Expenses/Index" />} />
                            <Route path=':expenseId' element={<LoadablePage page="Expenses/Profile" />} />
                        </Route>

                        {/* ================ Other Routes ============= */}
                        <Route path="settings" element={<LoadablePage page="Settings/Index" />} />

                        <Route path="visits" element={<Visits />} />
                        <Route path="notifications" element={<Notifications />} />
                        <Route path="appointments" element={<Appointments />} />

                        <Route path="*" element={<Error />} />
                    </Routes>
                </PageLayout>
            </AccountFilter>
        </React.Fragment>
    )
}

export default AccountRouter;