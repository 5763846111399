import React from "react";
import DotsMenu from "../DotsMenu";
import { Box, Heading, Anchor } from "../elements";

export default function CardHeader({ title, dotsMenu, button }) {
    return (
        <Box className="mc-card-header">
            {title && <Heading as="h4" className="mc-card-title">{title}</Heading>}
            {dotsMenu && <DotsMenu dots={dotsMenu.dots} dropdown={dotsMenu.dropdown} />}
            {button && <Anchor className={button.className ? `mc-btn ${button.className}` : "mc-btn primary sm m-2"} href={button.path} icon={button.icon} text={button.text} onClick={button.onClick} />}
        </Box>
    )
}