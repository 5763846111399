import React, { useContext, useState, useRef, useEffect } from 'react';
import { LanguageDropdown, WidgetDropdown, ProfileDropdown } from '../components/header';
import { Button, Section, Box, Input } from "../components/elements";
import { DrawerContext } from '../context/Drawer';
import { ThemeContext } from '../context/Themes';
import { Logo } from '../components';
import { header } from "../database/header";
import { useAuth } from '../context/Authentication';
import _ from 'lodash';

export default function Header() {
    const { drawer, toggleDrawer } = useContext(DrawerContext);
    const { theme, toggleTheme } = useContext(ThemeContext);
    const searchRef = useRef();
    const [scroll, setScroll] = useState("fixed");
    const [search, setSearch] = useState("");
    const auth = useAuth();
    const { staff } = auth;
    const [switches, setSwitches] = useState([
        { "path": "/my-profile", "icon": "person", "text": "My Account" },
        { "path": "/forgot-password", "icon": "privacy_tip", "text": "reset password" },
        { "path": "/logout", "icon": "lock", "text": "logout" }]
    );
    let items = [];

    window.addEventListener('scroll', () => {
        if (window.pageYOffset > 0) setScroll("sticky");
        else setScroll("fixed");
    });

    document.addEventListener('mousedown', (event) => {
        if (!searchRef.current?.contains(event.target)) {
            setSearch("");
        }
    })

    useEffect(() => {
        staff?.roles.forEach((role) => {
            switch (role) {
                case 'admin':
                    items.push({ "path": "/administration", "icon": "dashboard", "text": "Admin Dashboard" });
                    break;
                case 'receptionist':
                    items.push({ "path": "/administration", "icon": "admin_panel_settings", "text": "Receptionist Dashboard" });
                    break;
                case 'nurse':
                    items.push({ "path": "/medical", "icon": "admin_panel_settings", "text": "Medical Dashboard" });
                    break;
                case 'doctor':
                    items.push({ "path": "/medical", "icon": "admin_panel_settings", "text": "Doctor Dashboard" });
                    break;
                case 'pharmacist':
                    items.push({ "path": "/pharmacy", "icon": "local_pharmacy", "text": "Pharmacist Dashboard" });
                    break;
                case 'lab_scientist':
                    items.push({ "path": "/laboratory", "icon": "biotech", "text": "Laboratory Dashboard" });
                    break;
                case 'accountant':
                    items.push({ "path": "/account", "icon": "account_balance", "text": "Accountant Dashboard" });
                    break;
                case 'patient':
                    items.push({ "path": "/patient", "icon": "admin_panel_settings", "text": "Patient Dashboard" });
                    break;
                default:
                    items.push({ "path": "/", "icon": "person", "text": "home" });
                    break;
            }
        });

        setSwitches([...items, ...switches]);
    }, [staff]);


    return (
        <Section as="header" className={`mc-header ${scroll}`}>
            <Logo
                src={header?.logo.src}
                alt={header?.logo.alt}
                name={header?.logo.name}
                href={header?.logo.path}
            />
            <Box className="mc-header-group">
                <Box className="mc-header-left">
                    <Button
                        icon={header?.search.icon}
                        className="mc-header-icon search"
                        onClick={() => setSearch("show")}
                    />
                    <Button
                        icon={drawer ? "menu_open" : "menu"}
                        className="mc-header-icon toggle"
                        onClick={toggleDrawer}
                    />
                    <Box className={`mc-header-search-group ${search}`}>
                        <form className="mc-header-search" ref={searchRef}>
                            <Button className="material-icons">{header?.search.icon}</Button>
                            <Input type="search" placeholder={header?.search.placeholder} />
                        </form>
                    </Box>
                </Box>

                <Box className="mc-header-right">
                    <Button
                        icon={theme}
                        title={header.theme.title}
                        onClick={toggleTheme}
                        className={`mc-header-icon ${header.theme.addClass}`}
                    />

                    <WidgetDropdown
                        icon={header.notify.icon}
                        title={header.notify.title}
                        badge={header.notify.badge}
                        addClass={header.notify.addClass}
                        dropdown={header.notify.dropdown}
                    />
                    <ProfileDropdown
                        name={staff?.title ? `${staff?.title} ${staff?.surname}` : staff?.surname}
                        image={header.profile.image}
                        username={_.startCase(staff?.firstName)}
                        dropdown={switches}
                    />
                </Box>
            </Box>
        </Section>
    );
}

